<template>
  <div>
    <h1>Formularer</h1>
    <v-data-table
      v-if="groupedTemplates?.length"
      :headers="headers"
      :items="groupedTemplates"
      density="compact"
      class="elevation-1"
      :items-per-page="50"
      item-value="name"
    >
      <template #item="{ item }">
        <tr @click="navigateToTemplate(item)" class="row-pointer">
          <td>{{ item.name }}</td>
          <td>{{ item.agreementName }}</td>
        </tr>
      </template>
    </v-data-table>
    <LoadingSpinner :visible="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import templateServices from '@/components/Templates/templateServices';
import { QuestionnaireTemplateSummary } from '@/components/Templates/QuestionnaireTemplateSummary';
import { LoadingSpinner } from '@dims/components';

interface TemplateGroup { name: string, agreementName: string, templates: QuestionnaireTemplateSummary[] };

const templates = ref<QuestionnaireTemplateSummary[] | null>(null);
const groupedTemplates = ref<TemplateGroup[]>([]);
const isLoading = ref(false);
const router = useRouter();

const headers = [
  { text: 'Name', value: 'name' },
  { text: 'Aftale', value: 'agreementName' }
];

async function load() {
  isLoading.value = true;
  templates.value = await templateServices.getAllTemplates();
  groupTemplatesByName();
  isLoading.value = false;
}
void load();

function groupTemplatesByName() {
  if (templates.value) {
    const grouped = templates.value.reduce<TemplateGroup[]>((acc, template) => {
      const group = acc.find(g => g.name === template.name && g.agreementName === template.agreementName);
      if (group) {
        group.templates.push(template);
      } else {
        acc.push({ name: template.name, templates: [template], agreementName: template.agreementName ?? '' });
      }
      return acc;
    }, []);
    groupedTemplates.value = grouped;
  }
}

async function navigateToTemplate(item: TemplateGroup) {
  await router.push(`/agreement/${item.agreementName}/templates/${item.name}`);
}
</script>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>

