<template>
  <v-card flat class="pa-7">
    <v-card-text>
      <h1 class="mt-0">
        {{ section.title }}
      </h1>
      <div
        v-if="section.text"
        class="table__content pre-formatted mb-8"
        v-html="section.text"
      ></div>
      <div class="float-right d-flex align-center text-accent mb-3">
        <v-btn @click="expandingPanel(false)" variant="text" color="accent">Fold ind</v-btn>|
        <v-btn @click="expandingPanel(true)" variant="text" color="accent">Fold ud</v-btn>
      </div>
      <div
        v-for="(nestedQuestion, index) in (questions || [])"
        :key="index"
        class="mb-10"
      >
        <TemplateQuestion
          :questionnaire
          :section
          :question="nestedQuestion"
          :level="level + 1"
          :panelState
          :debug
          @panelsUpdate="panelsUpdate"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {
  DeepQuestion,
  injectionKeys,
  QuestionnaireRoot,
  TemplateQuestion,
  TemplateQuestionPanelState,
} from '@dims/components';
import { computed, provide, ref } from 'vue';

const { level = 0, questionnaire, section, debug = false } = defineProps<{
  level?: number,
  questionnaire: QuestionnaireRoot,
  section: DeepQuestion,
  debug?: boolean,
}>();

/* Agreement-specific provider used in some question components.
  Here we just inject a dummy for preview */
provide(injectionKeys.agreementSpecificKey, {
  createFilter() { throw new Error('Not implemented in admin'); },
});

const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);

const questions = computed(() => section.questions);

function expandingPanel(value: boolean) {
  panelState.value = value
    ? TemplateQuestionPanelState.EXPANDED
    : TemplateQuestionPanelState.COLLAPSED;
}

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}

</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-header,
.v-expansion-panel-content {
  border: rgb(233 231 231) 1px solid !important;
  border-radius: 0 !important;
  font-family: Arial, sans-serif !important;
  line-height: 1.5rem !important;
  padding-left: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.variant-border {
  border: 1px rgb(var(--v-accent-base)) solid;
}

.variant-text {
  font-family: Oswald, sans-serif;
}
</style>
