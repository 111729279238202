import { User } from '@dims/components';

export interface QuestionnaireTemplateNote {
  version: QuestionnaireTemplateNoteVersion;
  user: User;
  date: Date;
  note: string;
}

export enum QuestionnaireTemplateNoteVersion {
  Edit = 'Edit',
  Publish = 'Publish',
  Copy = 'Copy',
}
