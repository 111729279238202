<template>
  <ArrowButton
    v-if="store.isIncognito"
    :to="'/login'"
    class="mt-4"
  >Log ind
  </ArrowButton>
  <div v-else>
    <div style="background-image: url('/img/formular.jpg');height:90vh;padding:50px;margin:-30px;">
      <v-card class="mt-4" width="600">
        <v-card-text>
          <ArrowButton :to="'/agreements'">DIMS Formularskabeloner</ArrowButton>
        </v-card-text>
      </v-card>

      <v-card class="mt-4" width="600">
        <v-card-text>
          <ArrowButton :to="'/templates'">Andre Formularskabeloner</ArrowButton>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowButton } from '@dims/components';
import { useStore } from '@/store/store';

const store = useStore();

</script>
