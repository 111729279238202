<template>
  <div>
    <DocumentTemplates :preview :template />
    <br />
    <div v-for="(section, idx) in sections" :key="idx">
      <PreviewSection
        :questionnaire="questionnaireWrapper"
        :section
        :level="1"
        :debug
      />
      <br />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Questionnaire,
  QuestionnaireTemplate,
  QuestionnaireRoot,
  ReadOnlyQuestionnaireWrapper,
  useBackend,
} from '@dims/components';
import { computed, ref } from 'vue';
import DocumentTemplates from './DocumentTemplates.vue';
import PreviewSection from './PreviewSection.vue';

const emit = defineEmits<{ updatePreview: [] }>();
const { preview, template, debug = false } = defineProps<{
  preview: Questionnaire,
  template: QuestionnaireTemplate,
  debug?: boolean }>();
const backend = useBackend();

const questionnaireWrapper = ref<QuestionnaireRoot>(
  new class extends ReadOnlyQuestionnaireWrapper {
    questionUpdated() {
      emit('updatePreview');
    }
  }(preview, backend),
);

const sections = computed(() => preview.questions);

</script>
<style scoped>
.pre-formatted {
  white-space: pre-wrap !important;
}
</style>
