<template>
  <h1 class="my-8">Aftaler og formularskabeloner i DIMS</h1>

  <v-data-table
    :items="agreements"
    :headers
    item-value="agreementName"
    density="compact"
    mobile-breakpoint="sm"
    :loading="isLoading"
    loading-text="Henter aftaler"
    no-data-text="Ingen aftaler"
    :sort-by="[{ key: 'agreementName', order: 'asc' }]"
    items-per-page="1000"
    :hide-default-footer="true"
  >
    <template #item.agreementName="{ item }">
      <router-link v-if="supportsQuestionnaire(item)" :to="`/agreement/${item.agreementName}`">
        {{ item.agreementName }}
      </router-link>
      <template v-else>
        {{ item.agreementName }}
      </template>
    </template>
    <template #item.description="{ item }">
      <div v-if="getDescription(item) === '-'">
        <v-tooltip>
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
            >mdi-minus-circle-outline</v-icon>
          </template>

          Ingen skabeloner.
        </v-tooltip>
      </div>
      <div v-else>
        {{ getDescription(item) }}
      </div>
    </template>
    <template #item.awardForms="{ item }">
      <v-list :items="item.templates">
        <template #subtitle="{ item: templateItem }">
          <v-btn prepend-icon="mdi-text-box-edit-outline" variant="outlined" @click="goToAdminTemplate(templateItem.adminTemplateId)">{{ templateItem.name }}</v-btn>
          <v-chip v-if="templateItem.awardForm === 'DirectAward'" class="ml-1">Rammeaftale</v-chip>
        </template>
      </v-list>
    </template>
    <template #item.webSiteUrl="{ item }">
      <div v-if="isValidUrl(item.webSiteUrl)">
        <v-icon class="mr-1" size="small">mdi-open-in-new</v-icon>
        <v-btn @click="goToAgreementUrl(item.webSiteUrl)">
          {{ item.webSiteUrl }}
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import agreementsService from '@/agreementsService';
import { AgreementConfigurationSummary } from '@/models/Agreement';
import { useRouter } from 'vue-router';

const agreements = ref<AgreementConfigurationSummary[]>([]);
const isLoading = ref(false);
const router = useRouter();

const headers = [
  { title: 'Aftale',
    value: 'agreementName',
    sortable: true,
    // Just sort test agreements to the bottom, otherwise keep the order
    sort: (a: string, b: string) => {
      if (a.startsWith('test') && !b.startsWith('test')) {
        return 1;
      }
      if (!a.startsWith('test') && b.startsWith('test')) {
        return -1;
      }
      return 0;
    },
  },
  { title: 'Formularværktøj', value: 'description' },
  { title: 'Skabeloner', value: 'awardForms' },
  { title: 'Website', value: 'webSiteUrl' },
];

async function load() {
  isLoading.value = true;
  agreements.value = await agreementsService.getAgreements();
  isLoading.value = false;
}
void load();

function goToAgreementUrl(url: string) {
  window.open(url);
}

async function goToAdminTemplate(adminTemplateId: string) {
  await router.push(`admin-template/${adminTemplateId}`);
}

function isValidUrl(url: string) {
  return url.startsWith('http');
}

function getDescription(item: AgreementConfigurationSummary) {
  if (item.agreementName.startsWith('test-0000')) {
    return 'Automatisk test. Må ikke ændres.';
  }
  if (item.agreementName.startsWith('test-')) {
    return 'Aftale til manuel test. Alle må ændre.';
  }
  if (item.agreementName.startsWith('50860017')) {
    return 'Brug i stedet DocumentDrafter.';
  }
  if (!item.templates.length) {
    return '-';
  }

  return 'Kan redigeres i formularværktøjet';
}

function supportsQuestionnaire(item: AgreementConfigurationSummary) {
  return item.agreementName !== '50860017' && item.agreementName !== '02700022';
}

</script>
