<template>
  <v-dialog v-model="dialog" scrollable>
    <template #activator="{ props }">
      <v-btn color="primary" v-bind="props" icon="mdi-information-outline" />
    </template>
    <v-card color="canvas">
      <v-toolbar flat>
        <v-toolbar-title>
          <h2 class="mt-0">Prispunkttyper</h2>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon="mdi-close" @click="dialog = false" />
      </v-toolbar>
      <v-divider class="my-0"></v-divider>
      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="configuration in configurations"
            :key="configuration.id"
            class="mt-2 sand"
          >
            <v-expansion-panel-title class="text-h6">
              {{ configuration.name }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-for="group in configuration.groups" :key="group.id">
                <div class="text-subtitle mt-7 mb-2">
                  {{ group.name }}
                </div>
                <v-table dense>
                  <template #default>
                    <thead>
                      <tr>
                        <th v-for="(header, idx) in headers(group)" scope="col" :key="idx">
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="row in group.rows" :key="row.id">
                        <td v-for="(item, idx) in row.texts" :key="idx">
                          <small>{{ item.value ? item.value : '-' }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-table>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  labelForPricePointEnum,
  PricePointGroupConfiguration,
  PricePointQuestionConfiguration,
  PricePointType,
} from '@dims/components';
import { ref } from 'vue';

const { configurations } = defineProps<{ configurations: PricePointQuestionConfiguration[] }>();
const dialog = ref(false);

function headers(group: PricePointGroupConfiguration) {
  const row = group.rows[0];
  return row?.texts.map((text) => headerLabel(text.type)) ?? [];
}

function headerLabel(header: string) {
  return labelForPricePointEnum(header as PricePointType);
}

</script>
