<template>
  <div v-if="pricePointSet">
    <div class="d-flex align-center mb-4">
      Vælg evt. ét spørgsmål af typen
      <v-chip class="mx-1" size="small" color="lightPurple">Numerisk værdi</v-chip>
      til at definere kontaktlængde/milepæle for denne prisliste.
    </div>
    <div class="d-flex align-center mb-4">
      Dermed vises et variabelt antal kolonner i prislisten, svarende til den værdi, som kunden indtaster i det valgte spørgsmål.
      <br />
      Hvis der ikke vælges et spørgsmål i listen, vises kun de faste kolonner til kunde og leverandør for denne prisliste.
    </div>
    <v-select
      v-model="pricePointSet.intervalsQuestionId"
      :items="intervalsQuestions"
      :disabled="readonly"
      item-title="name"
      item-value="id"
      variant="outlined"
      density="compact"
      clearable
      label="Kontaktlængde/milepæle"
    ></v-select>
    <div class="d-flex">
      <v-select
        v-model="pricePointSet.configurationId"
        :items="configurations"
        :disabled="readonly"
        item-title="name"
        item-value="id"
        variant="outlined"
        density="compact"
        clearable
        label="Type"
        @update:model-value="onConfigurationChange($event)"
        data-cy="pricePointSet.configurationId"
      ></v-select>
      <ConfigurationsOverview :configurations />
    </div>
    <v-autocomplete
      v-if="selectedConfiguration"
      v-model="pricePointSet.configurationGroupsIds"
      :items="selectedConfigurationGroups"
      :disabled="readonly"
      chips
      label="Grupper"
      item-title="name"
      item-value="id"
      multiple
      variant="outlined"
      clearable
    >
      <template #chip="data">
        <v-chip>
          {{ data.item.raw.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script setup lang="ts">
import {
  PricePointSetQuestionDefinition,
  QuestionDefinition,
  QuestionnaireTemplate,
  PricePointQuestionConfiguration,
} from '@dims/components';
import { computed, ref } from 'vue';
import { intervalsItemText, intervalsQuestions as getIntervalsQuestions } from '@/utils';
import templateServices from '@/components/Templates/templateServices';
import ConfigurationsOverview from './ConfigurationsOverview.vue';

const { question, questions, template, readonly = false } = defineProps<{
  question: PricePointSetQuestionDefinition,
  questions: QuestionDefinition[],
  template: QuestionnaireTemplate,
  readonly?: boolean,
}>();

const configurations = ref<PricePointQuestionConfiguration[]>([]);
const selectedConfiguration = ref<string | null>(null);

const pricePointSet = computed(() => question.pricePointSet);

const intervalsQuestions = computed(() => getIntervalsQuestions(questions).map((q) => ({
  name: questionText(q),
  id: q.questionDefinitionId,
})));

const selectedConfigurationGroups = computed(() => configurations.value
  .find((c) => c.id === selectedConfiguration.value)
  ?.groups.map((g) => ({
    name: g.name,
    id: g.id,
  })));

async function load() {
  if (template.pricePointQuestionsConfigurationId) {
    const configuration = await templateServices.getPricePointQuestionConfiguration(template.pricePointQuestionsConfigurationId);
    configurations.value = configuration.configurations;

    if (pricePointSet.value?.configurationId) {
      selectedConfiguration.value = pricePointSet.value.configurationId;
    }
  }
}
void load();

function questionText(q: QuestionDefinition) {
  return intervalsItemText(
    template.questions,
    q.questionDefinitionId,
  );
}

function onConfigurationChange(configurationId: string) {
  selectedConfiguration.value = configurationId;

  if (pricePointSet.value?.configurationGroupsIds) {
    pricePointSet.value.configurationGroupsIds = [];
  }
}

</script>
