import { QuestionDefinition } from "@dims/components";
import { QuestionDefinitionChange } from './components/Templates/QuestionnaireTemplateWithChanges';

export function getLastChangesCountText(changesCount: number) {
  if (changesCount === 1) {
    return '1 ændring';
  }

  return `${changesCount} ændringer`;
}

export function getLastChangedByText(question: QuestionDefinition, templateChanges: QuestionDefinitionChange[]) {
  const changesCount = getLastChangesOnQuestion(question, templateChanges).filter((c) => c.change !== 'QuestionAdded').length;

  if (changesCount === 1) {
    return ' + 1 efterfølgende ændring';
  }

  if (changesCount > 1) {
    return ` + ${changesCount} efterfølgende ændringer`;
  }

  return '';
}

export function getLastChanges(question: QuestionDefinition, templateChanges: QuestionDefinitionChange[], changeFilter?: string): QuestionDefinitionChange[] {
  if (changeFilter === 'QuestionRemoved') {
    return templateChanges.filter((tc) => tc.rootQuestionDefinitionId === question.questionDefinitionId && tc.change === changeFilter);
  }

  if (changeFilter) {
    return templateChanges.filter((tc) => tc.questionDefinitionId === question.questionDefinitionId && tc.change === changeFilter);
  }

  return templateChanges.filter((tc) => tc.questionDefinitionId === question.questionDefinitionId);
}

export function getLastChangesOnQuestion(question: QuestionDefinition, templateChanges: QuestionDefinitionChange[]) {
  const questionChanges = getLastChanges(question, templateChanges);

  return questionChanges.filter((c) => c.change !== 'SubquestionChanges' && c.change !== 'QuestionRemoved');
}

export function hasSubquestionChanges(question: QuestionDefinition, templateChanges: QuestionDefinitionChange[]) {
  return getLastChanges(question, templateChanges, 'SubquestionChanges').length > 0;
}
