<template>
  <QuestionCardItemGroup
    v-if="root && rootCards"
    :cards="rootCards"
    @cardClick="onCardClicked"
    :title="''"
  />
  <template v-else>
    <QuestionCardItemGroup
      v-for="category in questionCardCategories(features.FlexRequirements)"
      :key="category.title"
      :cards="category.cards"
      @cardClick="onCardClicked"
      :title="category.title"
    />
  </template>
</template>

<script setup lang="ts">
import {
  QuestionTypeCard,
  questionCardCategories,
} from '@/models';
import QuestionCardItemGroup from './QuestionCardItemGroup.vue';
import { useFeatures } from '@dims/components';

const emit = defineEmits<{ questionTypeSelected: [QuestionTypeCard] }>();
const { root = false, enableRootLevelRepeater } = defineProps<{ root?: boolean, enableRootLevelRepeater?: boolean }>();

const features = useFeatures();

function onCardClicked(card: QuestionTypeCard) {
  emit('questionTypeSelected', card);
}

const rootCards = questionCardCategories(features.FlexRequirements)[0]?.cards.filter(
  (card) => ((card.type === 'Section' && card.title === 'Sektion') || (enableRootLevelRepeater && card.type === 'Repeater' && card.title === 'Repeater sektion')),
);

</script>
