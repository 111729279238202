<template>
  <div v-if="intervals">
    <v-text-field
      v-model="intervals.unit"
      :disabled="readonly"
      density="compact"
      variant="outlined"
      label="Enhed (f.eks år / milepæl)"
    ></v-text-field>
    <v-row>
      <v-col>
        <NumericInput
          v-model="intervals.defaultValue"
          :disabled="readonly"
          variant="outlined"
          label="Default værdi"
        />
      </v-col>
      <v-col>
        <NumericInput
          v-if="!crossValidationMinEnabled"
          v-model="intervals.minValue"
          :disabled="readonly"
          variant="outlined"
          label="Min"
        />
        <v-text-field
          v-if="crossValidationMinEnabled"
          v-model="crossValidationMinTitle"
          :disabled="readonly"
          :readonly="true"
          variant="outlined"
          label="Minimum spg. id"
          @click="showCrossValidationDialogMin = true"
        />
        <CrossValidationDialog
          v-model="showCrossValidationDialogMin"
          :question="question"
          :initialSelectedSource="crossValidationMinValue"
          :template="template"
          crossValidationRule="MinValue"
          @confirmed="setCrossValidationRule"
          @cancelled="showCrossValidationDialogMin = false"
        />
        <v-checkbox
          v-model="crossValidationMinEnabled"
          label="Krydsvalidering"
          persistent-hint
          hint="crossValidation"
          :disabled="readonly"
          class="mt-n5"
          @change="enableCrossValidationRule('MinValue')"
        />
      </v-col>
      <v-col>
        <NumericInput
          v-if="!crossValidationMaxEnabled"
          v-model="intervals.maxValue"
          :disabled="readonly"
          variant="outlined"
          label="Max"
        />
        <v-text-field
          v-if="crossValidationMaxEnabled"
          v-model="crossValidationMaxTitle"
          :disabled="readonly"
          :readonly="true"
          variant="outlined"
          label="Maximum spg. id"
          @click="showCrossValidationDialogMax = true"
        />
        <CrossValidationDialog
          v-model="showCrossValidationDialogMax"
          :question="question"
          :initialSelectedSource="crossValidationMaxValue"
          :template="template"
          crossValidationRule="MaxValue"
          @confirmed="setCrossValidationRule"
          @cancelled="showCrossValidationDialogMax = false"
        />
        <v-checkbox
          v-model="crossValidationMaxEnabled"
          label="Krydsvalidering"
          persistent-hint
          hint="crossValidation"
          :disabled="readonly"
          class="mt-n5"
          @change="enableCrossValidationRule('MaxValue')"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { IntervalsQuestionDefinition, NumericInput, QuestionnaireTemplate } from '@dims/components';
import { computed, ref, onMounted } from 'vue';
import CrossValidationDialog from '../CrossValidationDialog.vue';
import { filterForCrossValidationQuestions } from '@/utils';

const { template, question, readonly = false } = defineProps<{
  template: QuestionnaireTemplate,
  question: IntervalsQuestionDefinition,
  readonly?: boolean,
}>();

const crossValidationMinEnabled = ref<boolean>(false);
const crossValidationMaxEnabled = ref<boolean>(false);

const crossValidationMinValue = createCrossValidationRule('MinValue');
const crossValidationMaxValue = createCrossValidationRule('MaxValue');

const showCrossValidationDialogMin = ref<boolean>(false);
const showCrossValidationDialogMax = ref<boolean>(false);

const crossValidationMinTitle = computed(() => {
  return filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === crossValidationMinValue.value)?.title ?? '';
});

const crossValidationMaxTitle = computed(() => {
  return filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === crossValidationMaxValue.value)?.title ?? '';
});

function setCrossValidationRule(crossValidationRule: string, questionDefinitionId: string) {
  if (crossValidationRule == "MinValue") {
    showCrossValidationDialogMin.value = false;
    crossValidationMinValue.value = questionDefinitionId;
  } else if (crossValidationRule == "MaxValue") {
    showCrossValidationDialogMax.value = false;
    crossValidationMaxValue.value = questionDefinitionId;
  }
}

function enableCrossValidationRule(ruleName: string) {
  const rule = question.crossValidations?.find(cv => cv.crossValidationRule === ruleName);

  if (rule) {
    rule.enabled = ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value;
  }
}

onMounted(() => {
  if (question.crossValidations?.filter(cv => cv.crossValidationRule == "MinValue" && cv.enabled).length) {
    crossValidationMinEnabled.value = true;
  }
  if (question.crossValidations?.filter(cv => cv.crossValidationRule == "MaxValue" && cv.enabled).length) {
    crossValidationMaxEnabled.value = true;
  }
});

const intervals = computed(() => question.intervals);

function createCrossValidationRule(ruleName: string) {
  return computed({
    get() {
      return question.crossValidations?.find(cv => cv.crossValidationRule === ruleName)?.questionDefinitionId ?? '';
    },
    set(val) {
      if (!question.crossValidations) {
        question.crossValidations = [];
      }

      let rule = question.crossValidations.find(cv => cv.crossValidationRule === ruleName);
      if (!rule) {
        rule = { crossValidationRule: ruleName, questionDefinitionId: val, enabled: ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value };
        question.crossValidations.push(rule);
      } else {
        rule.questionDefinitionId = val;
        rule.enabled = ruleName === 'MinValue' ? crossValidationMinEnabled.value : crossValidationMaxEnabled.value;
      }
    }
  });
}

</script>
