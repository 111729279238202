import { PricePointSetQuestionDefinition, QuestionDefinition, QuestionTypesEnum, SupplierAccess } from '@dims/components';

const traverse = (questions: QuestionDefinition[], dependencies: QuestionDefinition[], questionType: QuestionTypesEnum | null) => {
  questions.forEach((q) => {
    if (q.questionType === questionType) {
      dependencies.push(q);
    }
    if (questionType === null) {
      dependencies.push(q);
    }
    traverse(q.questions ?? [], dependencies, questionType);
  });
};

const isSection = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.SECTION.valueOf() && variant === undefined;
const isRepeater = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.REPEATER.valueOf() && (variant === undefined || variant === 'supplier-readonly-repeater');
const isText = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.TEXT.valueOf() && variant === undefined;
const isSimpleText = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.SIMPLETEXT.valueOf() && variant === undefined;
const isNumber = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.NUMBER.valueOf() && variant === undefined;
const isBoolean = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.BOOLEAN.valueOf() && variant === undefined;
const isBooleanOptions = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.BOOLEANOPTIONS.valueOf() && variant === undefined;
const isCheckboxList = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.SECTION.valueOf() && variant === 'checkbox-list';
const isOptions = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.OPTIONS.valueOf() && variant === undefined;
const isDate = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.DATE.valueOf() && variant === undefined;
const isPlaceholder = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.PLACEHOLDER.valueOf() && variant === undefined;
const isIntervals = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.INTERVALS.valueOf() && variant === undefined;
const isPricePointSet = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.PRICEPOINTSET.valueOf() && variant === undefined;
const isFileDownload = (questionType: string, variant?: string): boolean => questionType === QuestionTypesEnum.FILEDOWNLOAD.valueOf() && variant === undefined;

const isAccessEditable = (questionType: string, variant?: string) => isSection(questionType, variant)
  || isRepeater(questionType, variant)
  || isText(questionType, variant)
  || isSimpleText(questionType, variant)
  || isNumber(questionType, variant)
  || isBoolean(questionType, variant)
  || isBooleanOptions(questionType, variant)
  || isCheckboxList(questionType, variant)
  || isOptions(questionType, variant)
  || isDate(questionType, variant)
  || isPlaceholder(questionType, variant)
  || isIntervals(questionType, variant)
  || isPricePointSet(questionType, variant)
  || isFileDownload(questionType, variant); // SKI fil upload.

export const isQuestionAccessEditable = (question: QuestionDefinition) => isAccessEditable(question.questionType.valueOf(), question.variant);

const isPricePointSetVariant1 = (questionType: string, variant?: string) => questionType === QuestionTypesEnum.PRICEPOINTSET.valueOf()
  && variant === 'pricepoint-hide-customer-values';
const isPricePointSetVariant2 = (questionType: string, variant?: string) => questionType === QuestionTypesEnum.PRICEPOINTSET.valueOf()
  && variant === 'pricepoint-hide-customer-question';
const isPricePointSetVariant3 = (questionType: string, variant?: string) => questionType === QuestionTypesEnum.PRICEPOINTSET.valueOf()
  && variant === 'pricepoint-hide-customer-question-no-intervals';
const isCustomerPricePointWithRepeater = (questionType: string, variant?: string) => questionType === QuestionTypesEnum.SECTION.valueOf()
  && variant === 'customer-pricepoint-with-repeater';

export const getDefaultAccessibleForCustomer = (questionType: string, variant?: string) => {
  const isPricePointSetVariant2Or3 = isPricePointSetVariant2(questionType, variant)
    || isPricePointSetVariant3(questionType, variant);

  // Most question types are by default visible to customer
  // Return true, unless question is one of the following variants
  return !isPricePointSetVariant2Or3
    && !(variant === 'supplier-file-upload')
    && !(variant === 'supplier-quantity-discount');
};

export const getDefaultAccessForSupplier = (questionType: string, variant?: string) => {
  if (isPricePointSet(questionType, variant)
    || isPricePointSetVariant1(questionType, variant)
    || isPricePointSetVariant2(questionType, variant)
    || isPricePointSetVariant3(questionType, variant)
    || variant === 'supplier-file-upload'
    || variant === 'supplier-quantity-discount') {
    return 'ReadWrite';
  }
  if (isIntervals(questionType, variant)
    || questionType === QuestionTypesEnum.FILES.valueOf()
    || isCustomerPricePointWithRepeater(questionType, variant)) {
    return 'ReadOnly';
  }
  return 'None';
};

export const defaultQuestionAccess = (questionType: string, variant?: string): { accessibleForCustomer: boolean, accessForSupplier: SupplierAccess } => {
  const defaultAccessibleForCustomer = getDefaultAccessibleForCustomer(questionType, variant);
  const defaultAccessForSupplier = getDefaultAccessForSupplier(questionType, variant);
  return { accessibleForCustomer: defaultAccessibleForCustomer, accessForSupplier: defaultAccessForSupplier };
};

export const pricepointQuestions = (questions: QuestionDefinition[]): PricePointSetQuestionDefinition[] => {
  const result: QuestionDefinition[] = [];
  traverse(questions, result, QuestionTypesEnum.PRICEPOINTSET);
  return result as PricePointSetQuestionDefinition[];
};

export const intervalsQuestions = (questions: QuestionDefinition[]): QuestionDefinition[] => {
  const result: QuestionDefinition[] = [];
  traverse(questions, result, QuestionTypesEnum.INTERVALS);
  return result;
};

const allQuestions = (questions: QuestionDefinition[]): QuestionDefinition[] => {
  const result: QuestionDefinition[] = [];
  traverse(questions, result, null);
  return result;
};

const findIntervalsQuestion = (questions: QuestionDefinition[], id: string) => intervalsQuestions(questions).find((d) => d.questionDefinitionId === id);
export const findQuestion = (questions: QuestionDefinition[], id: string) => allQuestions(questions).find((d) => d.questionDefinitionId === id);

const questionPath = (tree: QuestionDefinition[], targetNode: QuestionDefinition) => {
  function buildPath(
    subTree: QuestionDefinition[],
    tNode: QuestionDefinition,
    currentPath: number[],
  ): number[] | undefined {
    for (const node of subTree) {
      if (node.questionDefinitionId === tNode.questionDefinitionId) {
        return [...currentPath, subTree.indexOf(node) + 1];
      } if (node.questions) {
        const path = buildPath(node.questions, tNode, [...currentPath, subTree.indexOf(node) + 1]);
        if (path) return path;
      }
    }
    return undefined;
  }

  const path = buildPath(tree, targetNode, []);

  return path;
};

export function itemText(templateQuestions: QuestionDefinition[], question: QuestionDefinition) {
  const toc = questionPath(templateQuestions, question);
  if (toc) {
    const formatted = toc
      .slice(1)
      .flatMap((r, i) => (i === 0 ? r : '.'.concat(String(r))))
      .join('');
    const rootIdx = toc[0];
    if (rootIdx) {
      const rootQ = templateQuestions[rootIdx - 1];
      return `${rootQ?.title}: ${formatted} ${question.title}`;
    }
  }
  return '';
}

export function filterForCrossValidationQuestions(questions: QuestionDefinition[] | undefined, excludedQuestionDefinitionId: string): QuestionDefinition[] {
  if (questions === undefined) {
    return [];
  }

  return questions
    .filter((q) =>
      (q.questionType == 'Number' || q.questionType == 'Intervals') && q.questionDefinitionId !== excludedQuestionDefinitionId
    )
    .concat(
      questions
        .filter(q => q.questions && Array.isArray(q.questions))  // Check if there are nested questions
        .flatMap(q => filterForCrossValidationQuestions(q.questions, excludedQuestionDefinitionId))  // Recursively get nested questions
    );
}

export function intervalsItemText(templateQuestions: QuestionDefinition[], id: string) {
  const question = findIntervalsQuestion(templateQuestions, id);
  return question ? itemText(templateQuestions, question) : '';
}
