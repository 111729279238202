<template>
  <v-dialog width="90%" v-model="visible">
    <v-card title="Navngiv template">
      <v-text-field
        v-model="template.publishedName"
        label="Navn"
        hide-details
        variant="outlined"
        density="compact"
        class="mr-5 ml-5 mb-10 mt-5"
      ></v-text-field>
      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="commitEdit()"
          class="primary-button"
        >
          Gem
        </v-btn>
        <v-btn
          @click="emit('closeDialog', false)"
          class="secondary-button"
        >
          Annuller
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <LoadingSpinner :visible="isLoading" />
  </v-dialog>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { LoadingSpinner, QuestionnaireTemplate } from '@dims/components';
import templateServices from '@/components/Templates/templateServices';

const emit = defineEmits<{
  closeDialog: [boolean],
}>();

const { template } = defineProps<{
  template: QuestionnaireTemplate;
}>();

const isLoading = ref(false);
const visible = defineModel<boolean>();

async function commitEdit() {
  isLoading.value = true;

  await templateServices.updateTemplateSummary(template.id, template.publishedName? template.publishedName : '');

  isLoading.value = false;

  emit('closeDialog', true);
}

</script>
