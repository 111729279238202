import { Condition, QuestionDefinition, RepeaterQuestionDefinition, OptionsQuestionDefinition, OptionDefinition, IntervalsDefinition, PricePointSetDefinition, SectionQuestionDefinition, IntervalsQuestionDefinition, PricePointSetQuestionDefinition, NumericQuestionDefinition, TextQuestionDefinition, BooleanQuestionDefinition, BooleanOptionsQuestionDefinition } from '@dims/components';
import { v4 as newUUID } from 'uuid';

/** Convenience functions for creating inputs programmatically */
function getDefaults(title: string) {
  const id = newUUID();
  return {
    name: id,
    title,
    questionDefinitionId: id,
    text: '',
    helpText: '',
    required: true,
    questions: undefined,
    dependsOn: undefined,
    defaultValue: undefined,
    readonly: false,
    variant: undefined,
  };
}
export function createTextInput(
  title: string,
  properties: Partial<TextQuestionDefinition>,
) {
  return {
    questionType: 'Text',
    ...getDefaults(title),
    ...properties,
  } satisfies TextQuestionDefinition;
}
export function createBoolInput(
  title: string,
  properties: Partial<BooleanQuestionDefinition>,
): BooleanQuestionDefinition {
  return {
    questionType: 'Boolean',
    ...getDefaults(title),
    ...properties,
  };
}
export function createBoolOptionsInput(
  title: string,
  properties: Partial<BooleanOptionsQuestionDefinition>,
): BooleanOptionsQuestionDefinition {
  return {
    questionType: 'BooleanOptions',
    ...getDefaults(title),
    ...properties,
  };
}
export function createNumberInput(
  title: string,
  properties: Partial<NumericQuestionDefinition>,
): NumericQuestionDefinition {
  return {
    questionType: 'Number',
    ...getDefaults(title),
    ...properties,
  };
}
export function createOptionsInput(
  title: string,
  options: OptionDefinition[],
  properties: Partial<OptionsQuestionDefinition>,
) {
  return {
    questionType: 'Options',
    ...getDefaults(title),
    options,
    ...properties,
  } satisfies OptionsQuestionDefinition;
}
export function createRepeater(
  title: string,
  questions: QuestionDefinition[],
  properties: Partial<RepeaterQuestionDefinition>,
) {
  return {
    questionType: 'Repeater',
    ...getDefaults(title),
    questions,
    ...properties,
  } satisfies RepeaterQuestionDefinition;
}
export function createSection(
  title: string,
  questions: QuestionDefinition[],
  properties: Partial<SectionQuestionDefinition>,
) {
  return {
    questionType: 'Section',
    ...getDefaults(title),
    questions,
    ...properties,
  } satisfies SectionQuestionDefinition;
}
export function createIntervalInput(
  title: string,
  intervals: IntervalsDefinition,
  properties: Partial<IntervalsQuestionDefinition>,
) {
  return {
    questionType: 'Intervals',
    ...getDefaults(title),
    intervals,
    ...properties,
  } satisfies IntervalsQuestionDefinition;
}
export function createPricePointSetInput(
  title: string,
  pricePointSet: PricePointSetDefinition,
  properties: Partial<PricePointSetQuestionDefinition>,
) {
  return {
    questionType: 'PricePointSet',
    ...getDefaults(title),
    pricePointSet,
    ...properties,
  } satisfies PricePointSetQuestionDefinition;
}
export function dependsOn(question: QuestionDefinition): Condition {
  return { operator: 'Or', operands: [question.questionDefinitionId] };
}
export function dependsOnAll(...questions: QuestionDefinition[]): Condition {
  return { operator: 'And', operands: questions.map((q) => q.questionDefinitionId) };
}
export function dependsOnIdsByAnd(...ids: string[]): Condition {
  return { operator: 'And', operands: ids };
}
export function dependsOnNot(...questions: QuestionDefinition[]): Condition {
  return { operator: 'Not', operands: questions.map((q) => q.questionDefinitionId) };
}
