<!-- validation rules - edited in a popup-dialog  -->
<template>
  <v-dialog width="400">
    <template #activator="{ props }">
      <span>
        Valideringsregler:
        <code>{{ selectedRulesList || "Ingen" }}</code>
        <v-btn icon="mdi-check-all" v-bind="props"></v-btn>
      </span>
    </template>
    <template #default="{ isActive }">
      <v-card>
        <v-card-text>
          <p>
            Valideringsregler:
          </p>
          <v-checkbox
            v-for="rule in allRules"
            v-model="selectedRules"
            :key="rule"
            :label="rule"
            :value="rule"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="isActive.value = false" class="primary-button">Luk</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { QuestionDefinition, ValidationRuleName, validationRuleNames } from '@dims/components';
import { computed } from 'vue';

const { editingQuestion } = defineProps<{ editingQuestion: QuestionDefinition }>();

const allRules = validationRuleNames;

const selectedRules = computed({
  set(selected: ValidationRuleName[]) {
    editingQuestion.validation = selected.map((name) => ({ rule: name }));
  },
  get() {
    return editingQuestion.validation?.map((r) => r.rule) ?? [];
  },
});

const selectedRulesList = computed(() => selectedRules.value.join(', '));

</script>
