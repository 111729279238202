<template>
  <v-dialog width="auto" v-model="dialog">
    <template #activator="{ props }">
      <v-btn class="ml-2 secondary-button-mini" v-bind="props">{{ activatorText }}</v-btn>
    </template>
    <v-card :title="text">
      <v-card-text>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onCancel()">Fortryd</v-btn>
        <v-btn class="ml-2 primary-button-mini" @click="onOk()">{{ activatorText }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emit = defineEmits<{ confirmed: [] }>();
const { activatorText, text } = defineProps<{
  activatorText: string,
  text: string,
}>();
const dialog = ref(false);

function onCancel() {
  dialog.value = false;
}
function onOk() {
  dialog.value = false;
  emit('confirmed');
}

</script>
