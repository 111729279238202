import {
  DeepQuestion,
  Questionnaire,
  OptionsQuestionDefinition,
  PricePointSetQuestionDefinition,
  PushDeepAnswer,
  QuestionDefinition,
  SectionQuestionDefinition,
  FileDownloadQuestionDefinition,
  IntervalsQuestionDefinition,
  TextQuestionDefinition,
  BooleanQuestionDefinition,
  NumericQuestionDefinition,
  SimpleTextQuestionDefinition,
} from '@dims/components';

export function isSection(question: QuestionDefinition): question is SectionQuestionDefinition {
  return question.questionType === 'Section';
}

export function isNumeric(question: QuestionDefinition): question is NumericQuestionDefinition {
  return question.questionType === 'Number';
}

export function isText(question: QuestionDefinition): question is TextQuestionDefinition {
  return question.questionType === 'Text';
}

export function isSimpleText(question: QuestionDefinition): question is SimpleTextQuestionDefinition {
  return question.questionType === 'SimpleText';
}

export function isRequirementVariant(question: QuestionDefinition) {
  return question.variant?.startsWith('requirement-variant');
}

export function isPricePointSet(
  question: QuestionDefinition,
): question is PricePointSetQuestionDefinition {
  return question.questionType === 'PricePointSet';
}

export function isIntervals(
  question: QuestionDefinition,
): question is IntervalsQuestionDefinition {
  return question.questionType === 'Intervals';
}

export function isOption(
  question: QuestionDefinition,
): question is OptionsQuestionDefinition {
  return question.questionType === 'Options';
}

export function isBool(question: QuestionDefinition): question is BooleanQuestionDefinition {
  return question.questionType === 'Boolean';
}

export function isSKIFiles(
  question: QuestionDefinition,
): question is FileDownloadQuestionDefinition {
  return question.questionType === 'FileDownload';
}

export function collectAnswers(questions: DeepQuestion[], collector: Map<string, string>) {
  questions.forEach((question) => {
    if (
      question.questionType !== 'Section'
      && question.questionType !== 'Repeater'
      && question.questionType !== 'Files'
      && question.questionType !== 'FileDownload'
      && question.questionType !== 'PricePointSet'
      && question.questionType !== 'Placeholder'
    ) {
      if (question.value !== undefined) {
        collector.set(question.questionId, question.value);
      }
    }
    if (question.questions) {
      collectAnswers(question.questions, collector);
    }
  });
}
/** Collect all answers from a questionnaire */
export function collectPreviewAnswers(preview: Questionnaire): PushDeepAnswer[] {
  const collector = new Map<string, string>();
  collectAnswers(preview.questions, collector);
  return Array.from(collector.entries()).map(([key, value]) => ({ questionId: key, value }));
}
