<template>
  <v-card flat class="pa-6">
    <template #title>
      <h3 class="header mt-0 text-primary">Preview dokumenter</h3>
    </template>
    <v-card-text>
      <br />
      <v-row no-gutters>
        <v-col
          v-for="wordTemplate in wordTemplates"
          :key="wordTemplate.id"
          lg="6"
          md="6"
          sm="12"
        >
          <v-btn
            @click.stop="showWordPreview(wordTemplate)"
            color="lightPurple"
            size="small"
            style="margin-bottom: 5px"
          >
            {{ wordTemplate.name }}
          </v-btn>
          <span v-if="debug" class="debug">{{ wordTemplate.id }}</span>
        </v-col>
      </v-row>
      <DocumentPreviewDialog
        v-if="previewTemplate"
        :title="previewTemplate && previewTemplate.name"
        :html="previewHtml"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { Questionnaire, QuestionnaireTemplate } from '@dims/components';

import { ref } from 'vue';
import { WordTemplate } from '@/models';
import templateServices from '../templateServices';
import { collectPreviewAnswers } from '../utilities';
import DocumentPreviewDialog from './DocumentPreviewDialog.vue';
import { useStore } from '@/store/store';

const { preview, template, debug = false } = defineProps<{
  preview: Questionnaire,
  template: QuestionnaireTemplate,
  debug?: boolean,
}>();
const previewTemplate = ref<WordTemplate | null>(null);
const previewHtml = ref<string | null>(null);
// initialized in created hook
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const wordTemplates = ref<WordTemplate[]>(null!);
const store = useStore();

async function load() {
  try {
    wordTemplates.value = await templateServices.getWordTemplates(template);
  } catch (error) {
    store.showSnackbarError('Kunne ikke hente Word skabeloner', error);
    wordTemplates.value = [];
  }
}
void load();

async function showWordPreview(wordTemplate: WordTemplate) {
  const answers = collectPreviewAnswers(preview);
  previewTemplate.value = wordTemplate;
  const html = await templateServices.getWordHtmlPreview(wordTemplate, answers);
  previewHtml.value = html;
}

</script>
<style scoped>
.header {
  font-size: 28px;
}
</style>
