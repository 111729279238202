<template>
  <v-card color="canvas">
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div>
          <div class="font-weight-bold">{{ displayLastUpdated() }}</div>
          <div class="text-caption">{{ backup.lastUpdatedBy?.fullName }}
          </div>
        </div>
        <v-chip v-if="active">Aktive version</v-chip>
        <v-btn v-else @click="selectBackup()" class="primary-button-mini">Skift til denne version</v-btn>
      </div>
      <small>{{ backup.id }}</small>
      <TemplateTreeView
        :template
        :templateChanges="[]"
        :questions="template.questions"
        :locator
        :readonly="true"
        :showIds
        :showAccess
        :showQuestionTypes
        :showDependencies
        :expandedStateKeeper
        @viewQuestion="viewQuestion"
        :isLoading
      />
      <QuestionDisplayDialog
        v-if="selectedQuestion"
        v-model="showQuestionDialog"
        :question="selectedQuestion"
        :template
        :locator
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import { QuestionnaireTemplate, QuestionDefinition } from '@dims/components';
import { DateTime } from 'luxon';
import QuestionLocator from '../QuestionLocator';
import { TemplateBackupHistoryEntry } from '../../TemplateBackupHistoryEntry';
import TemplateTreeView from '../TemplateTreeView.vue';
import QuestionDisplayDialog from '../QuestionDisplayDialog.vue';
import ExpandedStateKeeper from '../ExpandedStateKeeper';

const emit = defineEmits<{ selectBackup: [TemplateBackupHistoryEntry] }>();
const {
  template,
  backup,
  active = false,
  showIds = false,
  showAccess = true,
  showQuestionTypes = false,
  showDependencies = false,
} = defineProps<{
  template: QuestionnaireTemplate,
  backup: TemplateBackupHistoryEntry,
  active?: boolean,
  showIds?: boolean,
  showAccess?: boolean,
  showQuestionTypes?: boolean,
  showDependencies?: boolean,
  isLoading: boolean,
}>();
const showQuestionDialog = ref(false);
const selectedQuestion = ref<QuestionDefinition | null>(null);

const expandedStateKeeper = reactive(new ExpandedStateKeeper(template.questions, false));

function displayLastUpdated() {
  const timestampMillis = backup.lastUpdated * 1000;
  const dateTime = DateTime.fromMillis(timestampMillis);
  return dateTime.toFormat('dd-MM-yyyy HH:mm');
}

const locator = computed(() => new QuestionLocator(template));

function selectBackup() {
  emit('selectBackup', backup);
}

function viewQuestion(question: QuestionDefinition) {
  selectedQuestion.value = question;
  showQuestionDialog.value = true;
}

</script>
<style scoped>
.v-card {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
