<template>
  <v-dialog width="1000" v-model="dialog">
    <v-card>
      <v-card-title>
        Vælg krydsvalidering for <template v-if="crossValidationRule == 'MinValue'">minimum</template><template v-else>maksimum</template>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="selectedSource" density="compact">
          <v-radio
            v-for="(source, index) in filterForCrossValidationQuestions(template.questions, question.questionDefinitionId)"
            :key="index"
            :value="source.questionDefinitionId"
            class="mb-3"
          >
            <template #label>{{ source.title }} &nbsp;<span v-if="source.key" class="question-key" title="Nøgle">{{ source.key }}</span>&nbsp;<span class="question-id">{{ source.questionDefinitionId }}</span></template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="onCancel()">Annuller</v-btn>
        <v-btn class="ml-2 primary-button-mini" @click="onOk()">Gem</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { QuestionDefinition, QuestionnaireTemplate } from '@dims/components';
import { filterForCrossValidationQuestions } from '@/utils';

const emit = defineEmits<{ confirmed: [crossValidationRule: string, questionDefinitionId: string], cancelled: [] }>();
const { template, question, crossValidationRule, initialSelectedSource } =
  defineProps<{ template: QuestionnaireTemplate, question: QuestionDefinition, crossValidationRule: string, initialSelectedSource: string }>();

const dialog = ref(false);
const selectedSource = ref(initialSelectedSource);

function onCancel() {
  emit('cancelled');
}

function onOk() {
  const sourceQuestion = filterForCrossValidationQuestions(template.questions, question.questionDefinitionId).find(q => q.questionDefinitionId === selectedSource.value);

  if (sourceQuestion !== undefined) { // Since it's radio buttons, sourceQuestion should always be defined
    emit('confirmed', crossValidationRule, sourceQuestion.questionDefinitionId);
  }
}

</script>

<style scoped>

.question-id {
  font-size: 0.8em;
  color: #888 !important;
}

.question-key {
  font-style: italic;
  font-size: 0.8em;
  color: #888 !important;
}

</style>
